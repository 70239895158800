<template>
  <div class="bg-theme-tertiary flex flex-col">
    <div class="max-w-xs">

      <p class="font-semibold text-xl underline text-gray-400 mt-3">New Account Details</p>
      <div class="flex">
        <form class="space-y-4" @submit.prevent="handleSubmit">
          <div class="flex flex-col">
            <label for="email" class="mb-2 text-sm font-medium">Email:</label>
            <input v-model="data.email" type="email" id="email" required class="p-2 border border-gray-300 rounded text-black" />
          </div>
          <div class="flex flex-col">
            <label for="password" class="mb-2 text-sm font-medium">Password:</label>
            <input v-model="data.password" type="text" id="password" required class="p-2 border border-gray-300 rounded text-black" />
          </div>
          <div class="flex flex-col">
            <label for="name" class="mb-2 text-sm font-medium">Name:</label>
            <input v-model="data.name" type="text" id="name" required class="p-2 border border-gray-300 rounded text-black" />
          </div>

          <label for="phone" class="text-sm font-medium">Phone:</label>
          <div class="flex flex-row">
            <input v-model="data.phoneCountry" placeholder="Country" class="p-2 border border-gray-300 rounded text-black w-20"/>
            <input v-model="data.rawPhoneNumber" @input="formatPhoneNumber" placeholder="Enter phone number" class="p-2 border border-gray-300 rounded text-black"/>
          </div>
          <p>Your number: {{ data.e164PhoneNumber }}</p>

          <button type="submit" class="p-2 mt-4 bg-blue-500 text-white rounded hover:bg-blue-700">Create Account</button>
        </form>
      </div>

    </div>
  </div>
</template>

<script setup>
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getFunctions, httpsCallable } from "firebase/functions";
import { ref, watch, defineEmits} from 'vue';
import { createStripeCustomer } from '@/helpers';

const data = ref({
  email: '',
  password: '',
  name: '',
  phoneCountry: 'AU',
  rawPhoneNumber: '',
  e164PhoneNumber: '',
});

const formatPhoneNumber = () => {
  const phoneNumber = parsePhoneNumberFromString(data.value.rawPhoneNumber, data.value.phoneCountry.toUpperCase());
  if (phoneNumber && phoneNumber.isValid()) {
    data.value.e164PhoneNumber = phoneNumber.format('E.164');
  } else {
    data.value.e164PhoneNumber = 'Invalid phone number';
  }
}

const emit = defineEmits(['update:modelValue', 'submitted']);

// Watch for changes in inputValue and emit an update event
watch(data, (newValue) => {
  emit('update:modelValue', newValue);
});

// Emit the current value when the form is submitted
const handleSubmit = async () => {
  // Create User (cloud function will auto create user doc)
  let uid;
  try {
    uid = await createNewUser(data.value.name, data.value.email, data.value.e164PhoneNumber, data.value.password);
    console.log(`Created user: ${uid}`)
    // Send email with password reset link
  } catch (error){
      console.error(`issue creating account: ${error}`)
  }
  emit('submitted', uid);
}

const createNewUser = async (name, email, phone, password) => {
  const stripeId = await createStripeCustomer(name, email, phone);

  const functions = getFunctions();
  const registerNewUserFunction = httpsCallable(functions, "registerNewUser");

  return await registerNewUserFunction({
    email: email,
    phone: phone,
    password: password,
    name: name,
    stripeId: stripeId
  })
  .then(result => {
    console.log('User UID:', result.data);
    const userId = result.data;
    return userId;
  })
  .catch(error => {
    if (error.code === 'internal') {
      console.error('Error while registering user:', error.details.detail);
    }
    // handle other types of errors if necessary
    throw new Error(`Error: ${error.details.detail}`);
  });
};

</script>