import {
    addDoc,
    Timestamp,
    setDoc,
    updateDoc,
    query,
    where, 
    getDocs,
    limit,
    writeBatch,
    orderBy,
    deleteField,
    deleteDoc
} from "firebase/firestore";
import {    firestoreDb,    
            getMultiEntryDocRef, 
            getDoorReportCollectionRef, 
            getVideoCollectionRef,
            getVideoDocRef,
            getReportDocRef,
            addReportToVideo,
            getReportSettings,
            getEntryReports,
} from "@/firebase";
import {
    getDatetimeBeforeAndAfter,
} from "@/helpers";
const matchReportToVideoVersion = 'V1';

const removeOldReportsPromises = [];
const createReportDocPromises = [];
const matchReportToVideoV1Promises = [];
const addMultiEntryPromises = [];

const processDoorReport = async(processedReport, targetDatetime, selectedLocationId, duplicateSuspectsInfo) => {
    if (isReportValid(targetDatetime, processedReport)) {
        await removeOldReports(targetDatetime, selectedLocationId);
        // Need to also remove old multi entry doc
        await Promise.all(removeOldReportsPromises);

        await createReportDoc(selectedLocationId, processedReport);
        if (matchReportToVideoVersion == 'V1') {
            await matchReportToVideoV1(targetDatetime, selectedLocationId);
        }
        await firestoreAddMultiEntry(selectedLocationId, targetDatetime, duplicateSuspectsInfo);
        return true;
    }
    return false;
}

const removeOldReports = async(targetDatetime, selectedLocationId) => {
    // console.debug("Removing Old Reports...");

    const date = new Date(targetDatetime);
    const {dayStart, dayEnd} = getTimeBoundsForDay(date);

    // Remove reports from report collection
    const gymVisitsRef = getDoorReportCollectionRef(selectedLocationId);
    const q = query(
        gymVisitsRef,
        where("scan_time", ">=", dayStart),
        where("scan_time", "<=", dayEnd),
    )

    const batch = writeBatch(firestoreDb);

    const querySnapshot = await getDocs(q);
    // console.log(`Size: ${querySnapshot.size}`)
    querySnapshot.forEach((resDoc) => {
        // console.log(`Staging ${resDoc.id} for delete`)
        const docRef = getReportDocRef(selectedLocationId, resDoc.id);
        batch.delete(docRef)
    });

    const batchPromise = batch.commit();
    removeOldReportsPromises.push(batchPromise);

    // Remove suspect/ report info from video doc
    const videoRef = getVideoCollectionRef(String(selectedLocationId));
    const videoQuery = query(   videoRef, 
                                where("entry_time", ">=", dayStart),
                                where("entry_time", "<=", dayEnd),
                                orderBy("entry_time", "desc"),
                                limit(1000) 
                            );
    const videoQuerySnapshot = await getDocs(videoQuery);
    videoQuerySnapshot.forEach(async(resDoc) => {
        const vidRef = getVideoDocRef(selectedLocationId, resDoc.id);
        const vidDocPromise = updateDoc(vidRef, {
            checked_report: false,
            ['suspects']: deleteField(),
        })
        removeOldReportsPromises.push(vidDocPromise);
    });

    // console.debug("Removed Old Reports")
}

const firestoreAddMultiEntry = async (selectedLocationId, targetDatetime, duplicateSuspectsInfo) => {
    // console.debug("Adding firestore multientry...");
    const multiEntrySuspectDetails = {}
    const filteredMultiEntryIds = [] // Sometimes scans don't work and people tap multiple times to get in. Those situations lead to multiple taps but only 1 video recording. We exclude these cases. AI can catch if tailgating occured.
    const multiEntryIds = Object.keys(duplicateSuspectsInfo)
    const userReviewedObj = {}
    const {dayStart, dayEnd} = getTimeBoundsForDay(targetDatetime);

    // suspectId is actually just name (new report version removed card_number as id)
    for (const suspectId of multiEntryIds) {
        if (suspectId) {
            const videoRef = getVideoCollectionRef(String(selectedLocationId));
            const videoQuery = query(   videoRef, 
                                        where("suspects_card_numbers", "array-contains", suspectId),
                                        where("entry_time", ">=", dayStart),
                                        where("entry_time", "<=", dayEnd),
                                        orderBy("entry_time", "desc"),
                                        limit(50) 
                                    );
            const videoQuerySnapshot = await getDocs(videoQuery);
            // Only include multiple taps if multiple entries occured as well. (exclude multi-scans for those who couldn't open door on the first go)
            if (videoQuerySnapshot.size > 1) {
                filteredMultiEntryIds.push(suspectId)

                videoQuerySnapshot.forEach((resDoc) => {
                    const vidRef = getVideoDocRef(selectedLocationId, resDoc.id);
                    const vidDocPromise = updateDoc(vidRef, {
                        is_multi_entry: true,
                    })
                    addMultiEntryPromises.push(vidDocPromise);

                    // Get/Copy report info 
                    const vidDocData = resDoc.data();
                    const suspectInfo = {
                        suspects: vidDocData.suspects,
                        vid_path: vidDocData.path,
                        vid_id: resDoc.id
                    }
                    const isoString = vidDocData['entry_time'].toDate().toISOString();
                    if (suspectId in multiEntrySuspectDetails) {
                        multiEntrySuspectDetails[suspectId][isoString] = suspectInfo
                    } else {
                        const entryInfo = {}
                        entryInfo[isoString] = suspectInfo
                        multiEntrySuspectDetails[suspectId] = entryInfo;
                    }                
                });            
            }
            userReviewedObj[suspectId] = false;            
        }
        // console.debug("Added firestore multientry")
    }

    // multiEntryCollection to be simplified to serve as a reference for the unique multi entry suspects
    // Card share will use 'multi_entry' collections to get unique ids then cross reference with 'videoData' to sort and get the videos
    const multiEntryDocRef = getMultiEntryDocRef(selectedLocationId, targetDatetime);

    // The delete and readding of multientry doc is required to trigger the 'FilterMultiEntry' cloud function (only triggers on doc create)
    await deleteDoc(multiEntryDocRef); // Removing old multiEntry doc
    const setDocPromise = setDoc(multiEntryDocRef, {"suspects": multiEntrySuspectDetails, "user_reviewed": userReviewedObj}); // adding in new multiEntryDoc
    addMultiEntryPromises.push(setDocPromise);
    await Promise.all(addMultiEntryPromises);
}

const createReportDoc = async(selectedLocationId, processedReport) => {
    // console.debug("Creating report doc...");
    const reportSettings = await getReportSettings(selectedLocationId);
    for (let i = 1; i < processedReport.length; i++) {
        const entryEvent = processedReport[i]

        entryEvent["has_video"] = false; // Is set to true when an uploaded video clip cross references with this document
        entryEvent["created"] = Timestamp.fromDate(new Date());
        entryEvent["scan_time"] = Timestamp.fromDate(new Date(entryEvent["scan_time"]));
        
        const gymVisitsRef = getDoorReportCollectionRef(selectedLocationId);
        const addReportDocPromise = addDoc(gymVisitsRef, entryEvent, { merge: true });
        createReportDocPromises.push(addReportDocPromise);

        if (matchReportToVideoVersion == "V2") {
            const matchReportPromise = matchReportToVideoV2(selectedLocationId, entryEvent, reportSettings);
            createReportDocPromises.push(matchReportPromise);
        }
    }

    await Promise.all(createReportDocPromises);
    // console.debug("Finished creating docs for entry event in firestore");
}

const matchReportToVideoV2Promises = [];
const matchReportToVideoV2 = async (selectedLocationId, entryEvent, reportSettings) => {
    // console.debug("matching ReportToVideoV2...");
    const rangeExpansion = reportSettings["range_expansion_seconds"];
    const timeOffset = reportSettings["time_offset_seconds"];

    const scanDatetime = entryEvent["scan_time"].toDate();
    const targetDatetime = new Date(scanDatetime);
    targetDatetime.setTime(targetDatetime.getTime() + timeOffset*1000)
    const {before, after} = getDatetimeBeforeAndAfter(targetDatetime, rangeExpansion)

    const videoRef = getVideoCollectionRef(String(selectedLocationId));
    const videoQuery = query(   videoRef, 
                                where("entry_time", "<=", before),
                                where("door_id", "==", entryEvent["door_id"]),
                                orderBy("entry_time", "desc"),
                                limit(10) 
                            );
    const videoQuerySnapshot = await getDocs(videoQuery);

    videoQuerySnapshot.forEach( async (flaggedVid) => {
        const endTime = flaggedVid.data().end_time.toDate().getTime();

        if (endTime >= after.getTime()) {
            const suspect = {
                "name" : entryEvent.username,
                "door_id": entryEvent.door_id,
                "door": entryEvent.door,
                "card_number": entryEvent.card_number,
                "scan_time": entryEvent.scan_time,
            }

            const addReportToVideoPromise = addReportToVideo(selectedLocationId, flaggedVid.id, suspect);
            matchReportToVideoV2Promises.push(addReportToVideoPromise);  
        }
    }
);  
    return Promise.all(matchReportToVideoV2Promises);
}

const matchReportToVideoV1 = async(targetDatetime, selectedLocationId) => {
    // console.debug("matching ReportToVideoV1...");
    // Match event in door report to a video V2
    const {dayStart, dayEnd} = getTimeBoundsForDay(targetDatetime);

    const videoRef = getVideoCollectionRef(String(selectedLocationId));
    const videoQuery = query(   videoRef, 
                                where("entry_time", ">=", dayStart),
                                where("entry_time", "<=", dayEnd),
                                orderBy("entry_time", "desc"),
                                limit(1000) 
                            );

    
    const videoQuerySnapshot = await getDocs(videoQuery)
    
    // V1 HandlingError: None 
    videoQuerySnapshot.forEach( (flaggedVid) => {
            // const doorId = flaggedVid.data().door_id
            const startDatetime = flaggedVid.data().entry_time.toDate();
            const endDatetime = flaggedVid.data().end_time.toDate();

            // Returns any door reports that are within certain range of scan time. Range determined in the function implementation.
            const anotherPromise = getEntryReports(startDatetime, endDatetime, selectedLocationId).then((suspectsRes) => {
                const vidRef = getVideoDocRef(selectedLocationId, flaggedVid.id)

                const docUpdatePromise = updateDoc(vidRef, {
                    checked_report: true,
                    suspects_card_numbers: suspectsRes.names,
                    suspects: suspectsRes.suspects,
                })
                matchReportToVideoV1Promises.push(docUpdatePromise);                
            })
            matchReportToVideoV1Promises.push(anotherPromise)
        }
    );
    return Promise.all(matchReportToVideoV1Promises);
}

// Helpers
const isReportValid = (targetDatetime, processedReport) => {
    // console.log(processedReport[0]['scan_time'])
    const firstEntryDatetime = new Date(processedReport[0]['scan_time'])
    if(!areSameDay(targetDatetime, firstEntryDatetime)) {
        alert(`Please upload door report for ${targetDatetime.getDate()}/${targetDatetime.getMonth() + 1}/${targetDatetime.getFullYear()}. You have uploaded ${firstEntryDatetime.getDate()}/${firstEntryDatetime.getMonth() + 1}/${firstEntryDatetime.getFullYear()} door report`)
        
        // alert(`Error: Uploaded door report ${firstEntryDatetime.toISOString()} does not match the date ${targetDatetime.toISOString()}`)
        return false;
    }
    return true;
}

const areSameDay = (date1, date2) => {
    if (date1 && date2) {
        return (date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate());
    }
    else {
        return false;
    }
};

const getTimeBoundsForDay = (datetime) => {
    const day = datetime.getDate()
    const month = datetime.getMonth()
    const year = datetime.getFullYear()

    const dayStart = new Date(year, month, day, 0, 0, 0, 0);
    const dayEnd = new Date(year, month, day, 23, 59, 0, 0);

    return {
        dayStart: dayStart,
        dayEnd: dayEnd
    }
}

export {
    processDoorReport,
    removeOldReports,
    matchReportToVideoV1,
}