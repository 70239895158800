import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { DateTime } from 'luxon';
// Function to process CSV data and find duplicates in one go
//processCSVAndFindDuplicates
const process = async (csvString) => {
    const columnMappings = {
        'username': ['NAME', 'UserName', 'Member Name'],
        'scan_time': ['TIME', 'Visit Time', 'VisitTime', 'Entry Time'],
    };

    const duplicates = {};
    const usernameMap = new Map();

    // Parse the CSV data with PapaParse
    const parsedData = await Papa.parse(csvString, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => {
            const newHeader = header.trim();
            for (const standardColName in columnMappings) {
                const possibleColNames = columnMappings[standardColName]
                if (possibleColNames.includes(newHeader)) {
                    return standardColName;
                }
            }

            return newHeader;
        },
        complete: (results) => {
            // Iterate through the parsed data to find duplicates
            results.data.forEach(row => {
                // Clean up data: Trim all row values
                Object.keys(row).forEach(key => {
                    if (typeof row[key] === 'string') {
                        row[key] = row[key].trim();
                    }
                });

                if (row.scan_time) {
                    const format = "dd/MM/yyyy HH:mm:ss";
                    const dateStr = row.scan_time;
                    const timezone = "Australia/Sydney";
                    const luxonDateTime = DateTime.fromFormat(dateStr, format, { zone: timezone })
                    if (luxonDateTime.isValid) {
                        row.scan_time = luxonDateTime.toISO();
                    } else {
                        console.error("Invalid date:", luxonDateTime.invalid);
                    }
                }

                if (!usernameMap.has(row.username)) {
                    usernameMap.set(row.username, []);
                }

                const entries = usernameMap.get(row.username);
                if (row.scan_time !== ""){
                    entries.push({
                        "scan_time": row.scan_time,
                        "username": row.username
                    });
                }
            });
        }
    }).data;

    // After processing, filter out non-duplicates
    usernameMap.forEach((scanTimes, username) => {
        if (scanTimes.length > 1) {
            duplicates[username] = scanTimes;
        }
    });

    // Only return the needed columns
    const editedParsedData = parsedData.map(suspectEntry => {
        return {
            username: suspectEntry.username,
            scan_time: suspectEntry.scan_time,
            multiple_entries: suspectEntry.username in duplicates
        };
    });
    const filteredParsedData = editedParsedData.filter(suspectEntry => suspectEntry.scan_time !== '');
    const processedCsv = await convertCsvStringToBase64(Papa.unparse(filteredParsedData));

    return {
        report: filteredParsedData,
        suspects_info: duplicates,
        csv_attachment: processedCsv
    }
}

const processCSVAndFindDuplicates = async (file) => {
    let csvString;

    const fileExtension = file.name.split('.').pop();
    if (fileExtension == 'xlsx') {
        csvString = await convertXlsxToCsvString(file);
    }
    else if (fileExtension == 'csv') {
        csvString = await convertCsvToCsvString(file);
    }

    return process(csvString);
}

const convertCsvToCsvString = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        // Read the file as text
        reader.onload = function (e) {
        const csvString = e.target.result; // This is the CSV string
        resolve(csvString); // Resolve the promise with the CSV string
        };

        reader.onerror = (error) => reject(error); // Handle any read errors

        reader.readAsText(file); // Read the file as text
    });
};

const convertXlsxToCsvString = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
        try {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            // Assuming you want the CSV from the first sheet
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];

            // Convert sheet to CSV
            const csv = XLSX.utils.sheet_to_csv(worksheet);

            resolve(csv); // Resolve the promise with the CSV string
        } catch (error) {
            reject(error); // Reject the promise if there's an error
        }
        };

        reader.onerror = (error) => reject(error);

        reader.readAsArrayBuffer(file);
    });
};

const convertCsvStringToBase64 = (csvString) => {
    return new Promise((resolve, reject) => {
        // Create a Blob from the CSV string
        const blob = new Blob([csvString], { type: 'text/csv' });

        // Create a FileReader to read the Blob as a Base64 string
        const reader = new FileReader();

        reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Extract only the Base64 part
        resolve(base64String);
        };

        reader.onerror = (error) => reject(error);

        reader.readAsDataURL(blob); // Read the Blob as a data URL (Base64 encoded)
    });
};

export {
    processCSVAndFindDuplicates,
};
