<template>
  <header class="sticky top-0 bg-theme-primary shadow-lg">

    <button v-if="isMobile" @click="toggleNav"
            class="flex flex-row items-center gap-0 text-white m-0 bg-theme-primary bg-transparent border-b-2 border-teal-200 w-full">
        <!-- Logo/ link home -->
        <RouterLink :to="{ name: 'Home' }">
          <div class="flex items-center gap-3 justify-start m-3">
            <img src="../assets/fusorlabs_logo.png" class="h-12"/>
          </div>
        </RouterLink>

        <!-- Menu Icon -->
        <div class="flex flex-1 justify-end h-5 mb-2 mr-5">
          <svg fill="none" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 5H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3 12H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3 19H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
    </button>
    
    <nav
      v-if="!isCollapsed"
      class="flex flex-col sm:flex-row items-center justify-between gap-11 text-white py-6 m-0 p-16 bg-theme-primary bg-transparent border-b-2 border-teal-200"
    >
      <div v-if="!isMobile"
           class="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 hover:text-teal-200">
        <RouterLink :to="{ name: 'Home' }">
          <div class="flex items-center gap-3 justify-start">
            <img src="../assets/fusorlabs_logo.png" class="h-20"/>
          </div>
        </RouterLink>
      </div>

      <div  v-if="loggedIn && validSubscription || loggedIn && isAdmin" 
            class="gap-3 flex-1 w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        <div  :class="{ 'text-teal-200 font-bold text-xl border-b border-teal-200': route == '/inbox' }"
              class="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 hover:text-teal-200 hover:font-bold hover:text-xl">
          <RouterLink :to="{ name: 'Inbox' }">
            <div class="flex items-center gap-3">
              <p class="text-l">Inbox</p>
            </div>
          </RouterLink>       
        </div>

        <div  :class="{ 'text-teal-200 font-bold text-xl border-b border-teal-200': route == '/multi_entry' }"
              class="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 hover:text-teal-200 hover:font-bold hover:text-xl">
          <RouterLink :to="{ name: 'CardShare' }">
            <div class="flex items-center gap-3">
              <p class="text-l">Multi Entry</p>
            </div>
          </RouterLink>        
        </div>

        <div  v-if="isOwner"
              :class="{ 'text-teal-200 font-bold text-xl border-b border-teal-200': route == '/account'}"
              class="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 hover:text-teal-200 hover:font-bold hover:text-xl">
          <RouterLink :to="{ name: 'Account' }">
            <div class="flex items-center gap-3">
              <p class="text-l">Account</p>
            </div>
          </RouterLink>        
        </div>

      </div>
      
      <div class="flex flex-col">
        <div class="flex flex-col lg:flex-row items-center gap-5">
          <!-- Enable Notifications -->
          <button v-if="loggedIn"
                  class="flex flex-col items-center bg-transparent hover:text-teal-200 text-blue-700 font-semibold hover:border-teal-200 rounded" 
                  @click="onNotificationsClick">
            <svg v-if="isNotificationTokenValid" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
            </svg>

            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.143 17.082a24.248 24.248 0 003.844.148m-3.844-.148a23.856 23.856 0 01-5.455-1.31 8.964 8.964 0 002.3-5.542m3.155 6.852a3 3 0 005.667 1.97m1.965-2.277L21 21m-4.225-4.225a23.81 23.81 0 003.536-1.003A8.967 8.967 0 0118 9.75V9A6 6 0 006.53 6.53m10.245 10.245L6.53 6.53M3 3l3.53 3.53" />
            </svg>
            
            <h1 v-if="showNotificationEnabledMessage" class="text-white font-thin">Enabled!</h1>
          </button>

          <!-- Adjust Playback Speed -->
          <div v-if="loggedIn" class="flex flex-col justify-center">
            <button class="bg-transparent hover:text-teal-200 text-blue-700 font-semibold hover:border-teal-200 rounded" 
                    @click="showAdjustSpeed = !showAdjustSpeed">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z" />
              </svg>
            </button>
            <SliderPlaybackSpeed  v-if="$route.path !== '/' && showAdjustSpeed"
                                  class="absolute bottom-3"/>
          </div>

          <!-- Location Selection -->
          <div v-if="loggedIn">
            <ClientLocationsDropdown :handleSelect="onLocationChange"></ClientLocationsDropdown>
          </div>

          <div v-if="isAdmin && !validSubscription">
            <h1 class="text-red-400">Location Subscription Expired</h1>
          </div>

          <div>
            <ModalComponent v-if="!loggedIn" openButtonName="Sign In" :onModalClose="modalClosed">
              <template v-slot:openButton="{ onButtonClick }">
                <button @click="onButtonClick"
                        v-on:click="modalOpened"
                        class="bg-transparent hover:text-teal-200 text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-teal-200 rounded">
                  Sign In
                </button>
              </template>

              <template v-slot:body>
                <SignInComponent></SignInComponent>
              </template>
            </ModalComponent>          
          </div>

          <div>
            <button v-if="loggedIn"
                    class="bg-transparent hover:text-teal-200 text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-teal-200 rounded" 
                    @click="logOut">
              Log out
            </button> 
          </div>
          
        </div>
      </div>

    </nav>
  </header>
</template>

<script>
import { signOut } from 'firebase/auth'
import { firebaseAuth, firebaseMessaging, getUserCustomClaims, isSubscriptionValid, checkAdmin, updateRegistrationToken } from "@/firebase";
import { getToken } from "firebase/messaging";
import { ref, onMounted} from 'vue';
import { useRouter, useRoute} from 'vue-router'
import ClientLocationsDropdown from './ClientLocationsDropdown.vue';
import { useStateStore } from '@/stores/StateStore';
import ModalComponent from './ModalComponent.vue';
import SignInComponent from './auth/SignInComponent.vue'
import SliderPlaybackSpeed from './SliderPlaybackSpeed.vue';

export default {
  components: {
    ClientLocationsDropdown,
    SliderPlaybackSpeed,
    ModalComponent,
    SignInComponent,
  },
  setup() {
    const isNotificationTokenValid = ref(false);
    const showNotificationEnabledMessage = ref(false);
    const showAdjustSpeed = ref(false);

    const isAdmin = ref(false);
    const validSubscription = ref(true);

    const stateStore = useStateStore();

    const isCollapsed = ref(false);
    const isMobile = ref(false);

    const router = useRouter();
    const route = ref();
    route.value = useRoute().path

    const currUser = ref(firebaseAuth.currentUser);
    const isOwner = ref(false);
    const isRegistered = ref(false);
    const loggedIn = ref(false);
    loggedIn.value = currUser.value !== null ? true : false;

    const logOut = () => {
      stateStore.clearStore();
      signOut(firebaseAuth).then(() => {
        loggedIn.value = false;
        router.replace({ name: "Home" })
        }
      );
    }

    const register = () => {
      router.replace({ name: "Register" })
    }

    const blockNavUpdate = ref(false);
    const modalOpened = () => {
      blockNavUpdate.value = true;
    }
    const modalClosed = () => {
      blockNavUpdate.value = false;
    }

    const toggleNav = () => {
      isCollapsed.value = !isCollapsed.value
    }
    const updateNavBar = () => {
      if (!blockNavUpdate.value){
        if (window.innerWidth <= 768) {
          isMobile.value = true;
          isCollapsed.value = true;
        } else if (window.innerWidth > 768){
          isMobile.value = false;
          isCollapsed.value = false;
        }
      }
    }
    
    const onLocationChange = async() => {
      const customClaims = await getUserCustomClaims();
      const currLocationId = stateStore.userLocations[stateStore.selectedLocation]
      if (customClaims) {
        if (customClaims.location_permissions) {
          isOwner.value = customClaims.location_permissions[currLocationId] == 'owner';
        }
      }

      validSubscription.value = await isSubscriptionValid(currLocationId);
      if (!validSubscription.value && !isAdmin.value) {
        router.push({ path: 'subscription_expired'})
      }
      // Get paying owner of the currLocationId
    }

    // Firebase Messaging
    const onNotificationsClick = async () => {
      const prevNotificationPermission = Notification.permission;

      const status = await enableNotifications();
      if (status == "refreshed") {
        if (prevNotificationPermission === "granted") {
          alert("To disable notifications, change your site permissions.");
        }
        displayMessage();
      }
      else if (status == "refreshed" || status == "suscribed") {
        displayMessage();
      }
    }
    
    const enableNotifications = () => {
      return getToken(firebaseMessaging, {vapidKey: "BDmgke4_dGjSZK6exkBiPk_qZAtSDF9P1ryUYl_9CMhyoZi3TPI1SF7T6y1YzmDSLXDTsAJNrc_HJhNScpeuuow"})
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to firestore
          updateRegistrationToken(currUser.value.uid, Object.values(stateStore.userLocations), currentToken)
          stateStore.updateNotificationToken(currUser.value.uid);
          isNotificationTokenValid.value = true;
          console.log(currentToken);
          return "refreshed"
        }

        else {
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              console.log('Notification permission granted.');
              updateRegistrationToken(currUser.value.uid, Object.values(stateStore.userLocations), currentToken);
              stateStore.updateNotificationToken(currUser.value.uid);
              console.log(currentToken);
              return "suscribed"
            }
            return "not suscribed"
          });
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        return "error"
      });
    }
    
    // Shows Notifications "Enabled!" Message for 9 seconds 
    const displayMessage = () => {
      showNotificationEnabledMessage.value = true;
      setTimeout(() => {
            showNotificationEnabledMessage.value = false;
      }, 9000); // Hide the div after 9000 milliseconds
    }

    onMounted(async() => {
      window.addEventListener('resize', updateNavBar);
      updateNavBar();

      await firebaseAuth.onAuthStateChanged(async(user) => {
        if (user) {
          user.getIdToken(true); // Force refresh to pick up the latest custom claims changes.
          currUser.value = user;
        }
      });

      // Notification Token
      //  'lastUpdated' date is store alongside the registrationToken in firestore fcm_tokens collection
      //  to ensure token freshness, tokens older than 2 months old are deleted.
      //  The code below checks the age of the user's token and updates 'lastUpdated' in fcm_tokens to ensure it stays fresh.
      const today = new Date()
      const lastUpdated = stateStore.lastUpdateNotificationToken(currUser.value.uid);
      if (!lastUpdated) {
        await enableNotifications();
      }
      else{
        var Difference_In_Time = today.getTime() - lastUpdated.getTime(); 
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
        console.log(Difference_In_Days)   
        if (Difference_In_Days >= 60) {
          await enableNotifications();
        }
      }
      isNotificationTokenValid.value = Notification.permission === "granted"

      // Checks if user is admin
      isAdmin.value = await checkAdmin();
    });

    return {
      isNotificationTokenValid,
      showNotificationEnabledMessage,
      onNotificationsClick,
      showAdjustSpeed,

      isAdmin,
      validSubscription,
      currUser,
      onLocationChange,
      isOwner,
      isRegistered,
      logOut,
      register,
      loggedIn,
      route,
      isMobile,
      isCollapsed,
      toggleNav,
      modalOpened,
      modalClosed,
    }
  },
};

</script>